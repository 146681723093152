import React from 'react';
import CustomLoadingScreen from './SplashScreen/SplashScreenLoader';
import SplashScreen from './SplashScreen/SplashScreen';
import { initCamera, limitCameraY, resetCamera } from './Functions/CameraFunctions';
import { createScene } from './Functions/SceneFunctions';
import { initModel } from './Functions/ModelFunctions';
import { Overlay } from './Overlay/Overlay';
import { Camera, CubeTexture, Engine, EngineOptions, Scene, UniversalCamera } from '@babylonjs/core';
import { isProduzione, showFps, updateFps } from './Functions/share';
import { DescriptionPanel } from './Overlay/Utils/DescriptionPanel/DescriptionPanel';
import { ButtonsFloatingContainer } from './Overlay/Utils/ButtonsContainer/ButtonsFloatingContainer';
import { TourBloc } from './bloc/TourBloc';
import { GenericButton } from './share/ui/GenericButton';
import { Icons } from './share/utils';
import BlocBuilder from 'bloc-builder-react';
import { OperaType, TourTypes, VirtualTourStateType, VirtualTourStateTypes } from './bloc/TourBlocUtils';
import { animateCamera, keyDownHandler, onPointerDown, onPointerUp, startTour, stopTour } from './Functions/TourFunctions';
import Help from './Overlay/Utils/Help/Help';

const debugOption = {
  showFps: true
}


export class App extends React.Component<any, any> {

  // [Proprieta']
  canvas: any;
  engine!: Engine;
  scene!: Scene;
  camera!: Camera | UniversalCamera;
  cameraBox: any;
  meshes: any[] = [];
  light: any;
  texture!: CubeTexture;
  lastFrameTimestamp: number = new Date().getTime();
  tourBloc: TourBloc = new TourBloc();

  // [Functions]
  // CAMERA ----------------------------------------------
  // Init Camera
  initCamera: any;
  // Reset Camera Position
  resetCamera: any;
  // Animate camera
  animateCamera: any;
  limitCamera: any;
  lastCameraPosition: any;
  // Interval di movimento camera programmato
  moveLoop: any;
  // ---------------------------------------------------
  // [TOUR]
  startTour: any;
  stopTour: any;
  tourInterval: any;

  // Create Scene
  createScene: any;
  // Carica il modello
  initModel: any;
  // Listener pointerDown
  onPointerDown: any;
  // Listener pointerUp
  onPointerUp: any;

  keyDownHandler: any;

  constructor(props: any) {
    super(props);
    // Inizializza lo stato principale
    this.state = {
      showSplashScreen: true,
      lastPosition: 0,
      loadingPerc: 0,
      help: false
    }
    // Functions Bindings
    this.bindFunctions();
  }

  // Effettua il binding di tutte le funzioni esterne
  bindFunctions = () => {
    // SCENE FUNCTION
    this.createScene = createScene.bind(this);
    // CAMERA FUNCTIONS
    // MODEL FUNCTRIONS
    this.initModel = initModel.bind(this);
    this.initCamera = initCamera.bind(this);
    this.resetCamera = resetCamera.bind(this);
    this.limitCamera = limitCameraY.bind(this);
    this.onPointerDown = onPointerDown.bind(this);
    this.onPointerUp = onPointerUp.bind(this);
    this.animateCamera = animateCamera;
    this.startTour = startTour.bind(this);
    this.stopTour = stopTour.bind(this);
    this.keyDownHandler = keyDownHandler.bind(this);
  }

  hideSplashScreen = () => {
    // alert("hide splash screen");
    console.log("End loading");
    this.setState({ showSplashScreen: false });
  }


  setLoadingPerc = (val: number) => {
    this.setState({ loadingPerc: val })
  }
  // Inizializzazione
  componentDidMount() {



    this.canvas = document.getElementById('gav');

    const engineOptions: EngineOptions = {
      antialias: true,
      audioEngine: false,
      autoEnableWebVR: false,
      powerPreference: "high-performance",
      useHighPrecisionFloats: true,
      depth: true,
      alpha: true,
      desynchronized: true,
    }


    this.engine = new Engine(this.canvas, true, engineOptions);

    this.engine.loadingScreen = new CustomLoadingScreen(this.hideSplashScreen.bind(this));

    this.engine.displayLoadingUI();

    // [DEBUG]
    // alert("pre create scene");

    // call the createScene function
    this.createScene(this.setLoadingPerc)

    if (debugOption.showFps && !isProduzione()) {
      // Visualizzo gli fps
      showFps();
    }


    // ON SELECT OPERA
    this.tourBloc.selectedOpera.subscribe((snap: OperaType) => {
      if (snap) {
        console.log("snap", snap);
        this.animateCamera(this.scene, snap?.cameraPosition, snap?.targetPoint);
      }
    });

    /**
     * ON CHANGE VIRTUAL TOUR STATE
     */
    this.tourBloc.virtualTourState.subscribe((snap: VirtualTourStateType) => {
      if (snap === VirtualTourStateTypes.PAUSE) {
        this.stopTour();
      } else if (snap === VirtualTourStateTypes.PLAY) {
        this.startTour();
      } else {
        console.error("Unknown Wirtual tour type recived in App.");
      }
    });

    // RENDER LOOP
    this.engine.runRenderLoop(this.renderLoop);

    // Resize listener
    window.addEventListener('resize', () => {
      this.engine.resize();
      this.scene.render();
    }, { passive: true });


    // KEYDOWNS SHORTCUTS
    window.addEventListener('keydown', this.keyDownHandler, { passive: true });


    // [DEBUG] PRINT CAMERA TARGET AND POTITION ON CLICK
    // window.addEventListener('click', () => {
    //   // const direction = BABYLON.Ray.CreateNewFromTo(this.camera.position, this.camera.getTarget()).direction
    //   console.debug("Camera position", `${this.camera.position.x},${this.camera.position.y},${this.camera.position.z}`, "Camera target", this.camera.getTarget());
    // }, { passive: true });
  }


  renderLoop = () => {
    // DEBUG
    // Update fps
    if (debugOption.showFps && !isProduzione()) {
      updateFps(this.engine.getFps().toFixed());
    }
    // _______________________________________

    if (!(this.scene as any).paused) {
      if (this.camera) {
        // if (this.lastCameraPosition !== this.camera.position) {
        // Limita il movimento in altezza della camera
        this.limitCamera();
        // }

        // Verifica se e' richiesto movimento camera da bottoni
        // if (this.state.direction !== '') {
        //   this.cameraMoveChecker();
        // }

        this.lastCameraPosition = this.camera.position;
      }
      this.scene.render();
    }
  }

  // [DEBUG]
  // componentWillUnmount() {
  //   alert("Unespected unmount");
  // }

  render() {
    return (
      <React.Fragment>
        <SplashScreen loadingPerc={this.state.loadingPerc} active={this.state.showSplashScreen} />
        {this.state.showSplashScreen
          ||
          <Overlay>

            <Help
              active={this.state.help}
              close={() => {
                (this.scene as any).paused = false;
                this.setState({ help: false });
                this.canvas.focus();
              }}
            />

            <BlocBuilder
              subject={this.tourBloc.showDescription}
              builder={
                (showDesc: any) => {
                  return (
                    <DescriptionPanel showDescription={showDesc.data} />
                  )
                }
              }
            />


            <ButtonsFloatingContainer>

              <GenericButton onClick={() => { this.tourBloc.setTourType(TourTypes.UNSET); this.tourBloc.toggleTourState(VirtualTourStateTypes.PAUSE); this.tourBloc.setSelectedOperaById(null, 1); this.canvas.focus(); }} icon={Icons.RESET} label="Restart" />

              {/* MANUAL / VIRTUAL */}
              <BlocBuilder
                subject={this.tourBloc.tourType}
                builder={(tourType: any) => {
                  if (tourType.data === TourTypes.UNSET) {
                    console.log("Render bloc manual /virtual", tourType.data)
                    return (
                      <React.Fragment>
                        <GenericButton onClick={() => { this.tourBloc.setTourType(TourTypes.MANUAL); this.canvas.focus() }} icon={Icons.KEYS_WASD} bigIcon label="Manual Tour" />
                        <GenericButton onClick={() => { this.tourBloc.setTourType(TourTypes.VIRTUAL); this.canvas.focus() }} icon={Icons.PLAY} bigIcon label="Virtual Tour" />
                      </React.Fragment>
                    )
                  } else if (tourType.data === TourTypes.MANUAL) {
                    return (
                      <React.Fragment>
                        <GenericButton onClick={() => { this.tourBloc.setPrevOpera(); this.canvas.focus() }} icon={Icons.PREV} bigIcon label="Previous" />
                        <GenericButton onClick={() => { this.tourBloc.setNextOpera(); this.canvas.focus() }} icon={Icons.NEXT} bigIcon label="Next" />
                      </React.Fragment>
                    )
                  } else if (tourType.data === TourTypes.VIRTUAL) {
                    return (
                      <React.Fragment>
                        <BlocBuilder
                          subject={this.tourBloc.virtualTourState}
                          builder={(tourState: any) => {
                            if (tourState.data === VirtualTourStateTypes.PAUSE) {
                              return <GenericButton onClick={() => { this.tourBloc.toggleTourState(); this.canvas.focus() }} bigIcon icon={Icons.PLAY} label="Play" />
                            } else {
                              return <GenericButton onClick={() => { this.tourBloc.toggleTourState(); this.canvas.focus() }} bigIcon icon={Icons.PAUSE} label="Pause" />
                            }
                          }} />
                      </React.Fragment>
                    )
                  } else {
                    return <></>
                  }
                }}
              />

              <BlocBuilder
                subject={this.tourBloc.selectedOpera}
                builder={
                  (opera: any) => {
                    console.log("Opera in blocBuilder: ", opera);
                    if (opera.data === null) {
                      return <></>
                    } else {
                      return <GenericButton
                        onClick={() => {
                          this.tourBloc.showDescription.next(true);
                        }}
                        bigIcon
                        icon={Icons.SHOW}
                        label="Details"
                      />
                    }
                  }
                }
              />


              <GenericButton
                onClick={() => {
                  (this.scene as any).paused = true;
                  this.setState({ help: true });
                }} icon={Icons.HOW} bigIcon label="" />


              {/* RESET */}
              {/* <ResetButton onClick={this.props.reset} /> */}
              {/* TOUR BUTTON */}
              {/* <TourButton onClick={() => this.tourBloc.toggleTourState()} isTourPlayng={this.state.tourActive} /> */}
              {/* FIRST OPERA */}
              {/* <NextOperaButton onClick={this.goToFirstOpera} first={true} /> */}
              {/* PREV OPERA */}
              {/* {
                this.state.id
                &&
                <NextOperaButton prev={true} onClick={() => this.tourBloc.setNextOpera()} />
              } */}
              {/* NEXT OPERA */}
              {/* {
                this.state.id
                &&
                <NextOperaButton onClick={() => this.tourBloc.setPrevOpera()} />
              } */}


            </ButtonsFloatingContainer>
          </Overlay>
        }
      </React.Fragment>
    );
  }
}

export default App;

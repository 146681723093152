
export interface ILoadingScreen {
    //What happens when loading starts
    displayLoadingUI: () => void;
    //What happens when loading stops
    hideLoadingUI: () => void;
}


export default class CustomLoadingScreen implements ILoadingScreen {
    //optional, but needed due to interface definitions
    public loadingUIBackgroundColor: string = "ffffff";
    constructor(public onLoad: Function) { }
    public displayLoadingUI() {
        // // alert(this.loadingUIText);
    }

    public loadingUIText = ""

    public hideLoadingUI() {
        // // alert("Loaded!");
        this.onLoad();
    }
}
import React from 'react';
import { Icons } from '../../../share/utils';
import style from './FocusPage.module.css';

export interface FocusPageProps {
    imageUrl: string,
    onCloseMe: () => void
}

export function FocusPage(props: FocusPageProps) {
    return (
        <div className={style.container} onClick={(e) => { e.stopPropagation(); props.onCloseMe() }} >
            {/* <div className={`${style.iconContainer} flex j-start w-100`}>
                <i className={`icon-left ${style.icon}`} ></i>
            </div> */}
            <div className={`d-panel-icon-container d-panel-top-level-button ${style.iconContainer}`} >
                <div className="icon-label  d-panel-top-level-button">Chiudi</div>
                <div className="d-panel-close-icon  d-panel-top-level-button" onClick={() => props.onCloseMe()} >
                    <i className={`${Icons.FULLSCREEN_LEAVE} d-panel-top-level-button`} ></i>
                </div>
            </div>
            <div className={`${style.imageContainer} flex-center-all`} >
                <img className={style.image} src={props.imageUrl} alt="Opera" onContextMenu={(e) => { e.preventDefault(); return false }} onClick={(e) => { e.stopPropagation() }} />
            </div>
        </div>
    )
}
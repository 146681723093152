import { Color3, Color4, CubeTexture, HemisphericLight, Scene, SceneOptimizer, SceneOptimizerOptions, SceneOptions, Vector3 } from "@babylonjs/core";
import App from "../App";


// CreateScene function that creates and return the scene
export function createScene(this: App, loadingPerc: (val: number) => void) {
    const sceneOptions: SceneOptions = {
        useClonedMeshMap: true,
        useGeometryUniqueIdsMap: true,
        useMaterialMeshMap: true,
        virtual: false
    }
    this.scene = new Scene(this.engine, sceneOptions);
    this.initCamera();
    this.scene.activeCamera = this.camera;

    // OPTIMIZER
    const optimizeOptions: SceneOptimizerOptions = new SceneOptimizerOptions(60, 2000)
    SceneOptimizer.OptimizeAsync(this.scene, optimizeOptions);


    // GRAVITY
    this.scene.gravity = new Vector3(0, 0, 0);

    // ENVIROMENT
    this.texture = CubeTexture.CreateFromPrefilteredData(process.env.PUBLIC_URL + "/assets/textures/newenvironment.env", this.scene, false, true);
    this.texture.level = 0.5;
    this.texture.scale(100)
    this.texture.invertZ = true;
    this.scene.environmentTexture = this.texture;

    // SCENE CAMERA

    // SCENE DEFAULT COLORS
    this.scene.clearColor = new Color4(1, 1, 1, 0.8)
    this.scene.ambientColor = new Color3(1, 0.9, 0.9);

    // INIZIALIZZA IL MODELLO
    this.initModel(loadingPerc);


    // HEMISPHERE LIGHTS
    // const ambientLight = new HemisphericLight('light', new Vector3(0, 1, 0), this.scene)
    // ambientLight.intensity = 0.3;
    // const ambientLight1 = new HemisphericLight('light', new Vector3(0, -1, 0), this.scene)
    // ambientLight1.intensity = 0.2;


    this.scene.onPointerDown = this.onPointerDown;
    this.scene.onPointerUp = this.onPointerUp;
}



// const ambientLight2 = new BABYLON.HemisphericLight('light', new BABYLON.Vector3(-2.5, 0, 0), this.scene)
// ambientLight2.intensity = 0.1;
// const ambientLight3 = new BABYLON.HemisphericLight('light', new BABYLON.Vector3(0, 0, 2.5), this.scene)
// ambientLight3.intensity = 0.1;
// const ambientLight4 = new BABYLON.HemisphericLight('light', new BABYLON.Vector3(0, 0, -2.5), this.scene)
// ambientLight4.intensity = 0.1;

// const light1 = new PointLight("centro", new Vector3(-5.82, 2.7, -6.75), this.scene);
// light1.intensity = 5
// light1.range = 100;


// POINT LIGHTS
// const light1 = new PointLight("centro", new Vector3(-4.618656017061486, 2.6, -3.0065582865117526), this.scene);
// light1.intensity = 1.4
// light1.range = 5
// var light5 = new PointLight("1", new Vector3(-3.8, 2.6, -6.553099596391722), this.scene);
// light5.intensity = 2.3
// light5.range = 20
// var light4 = new PointLight("5-11", new Vector3(-12.350167168489921, 2.70, -6.55977156110728), this.scene);
// light4.intensity = 2.3
// light4.range = 20
// var light8 = new PointLight("25-29", new Vector3(-2.436417093359367, 2.70, -10.290234076925115), this.scene);
// light8.intensity = 1.4
// light8.range = 5
// var light3 = new PointLight("12-13", new Vector3(-8.789158412728638, 2.70, -6.556438190655104), this.scene);
// light3.intensity = 1.4
// light3.range = 5
// var light6 = new BABYLON.PointLight("7-10", new BABYLON.Vector3(-8.747221863693756, 2.70, -6.559506726897342), this.scene);
// light6.intensity = 1.4
// light6.range = 5
// const light2 = new BABYLON.PointLight("14-16", new BABYLON.Vector3(-7.752706785107131, 2.70, -2.6220865472194097), this.scene);
// light2.intensity = 1.4
// light2.range = 5
// var light7 = new BABYLON.PointLight("25-29", new BABYLON.Vector3(-5.31455440123956, 2.70, -0.8542142691104605), this.scene);
// light7.intensity = 1.4
// light7.range = 5
// JSON CON DESCRIZIONI OPERE
import descs from '../data/opera-descriptions.json';
// JSON CON POSIZIONE CAMERA E TARGET POINT DI OGNI OPERA
import poss from '../data/opera-camera-position.json';

import { BehaviorSubject } from 'rxjs';

import { Vector3 } from '@babylonjs/core';

import { OperaData, OperasIdIndexMap, OperaType, TourType, TourTypes, VirtualTourStateType, VirtualTourStateTypes } from './TourBlocUtils';





export class TourBloc {
    /**
     * Istanza singleton statica
     */
    static instance: TourBloc;

    /**
     * Tipo di tour selezionato
     * Observable
     */
    public tourType!: BehaviorSubject<TourType>;

    /**
     * Tipo di tour selezionato
     * Observable
     */
    public virtualTourState!: BehaviorSubject<VirtualTourStateType>;

    /**
     * Opera selezionata
     * Observable
     */
    public selectedOpera!: BehaviorSubject<OperaType>;

    /**
     * Boolean che indica se il pannello di descrizione e' o meno in mostra.
     * Observable
     */
    public showDescription!: BehaviorSubject<boolean>;


    /**
     * Mappa che contiene gli url delle mesh dei quadri, da abbinare alle proprieta' note dell'opera.
     */
    public imagesMap!: { [key: string]: string };



    /**
     * Descrizioni di tutte le opere.
     */
    private _descriptions: { [key: string]: OperaData } = (descs as unknown as { [key: string]: OperaData });

    /**
     * Posizioni camera e target di tutte le opere.
     */
    private _positions: { [key: string]: { position: Vector3, target: Vector3 } } = (poss as unknown as { [key: string]: { position: Vector3, target: Vector3 } });


    /**
    * Classe Bloc per la gestione dello stato del tour.
    * Singleton
    */
    constructor() {
        if (!TourBloc.instance) {
            // INIZIALIZZAZIONE VALORI
            this.tourType = new BehaviorSubject<TourType>(TourTypes.UNSET)
            // TODO Inizializzare con ultimo punto visitato
            this.selectedOpera = new BehaviorSubject<OperaType>(null)
            // TODO Inizializzare con ultimo punto visitato
            this.virtualTourState = new BehaviorSubject<VirtualTourStateType>(VirtualTourStateTypes.PAUSE)
            // Show description
            this.showDescription = new BehaviorSubject<boolean>(false);

            this.imagesMap = {};

            // INSTANCE
            TourBloc.instance = this;
        }
        // RETURNING INSTANCE
        return TourBloc.instance;
    }

    /**
     * @param val Tipo di tour scelto
     * Imposta il tipo di tour propagando il valore ai subscribers.
     */
    setTourType = (val: TourType) => {
        this.tourType.next(val);

        // ESEGUO IL DEFAULT PER OGNI TIPO
        switch (val) {
            case TourTypes.MANUAL:
                this.setSelectedOperaById(null, 1);
                break;
            case TourTypes.VIRTUAL:
                this.virtualTourState.next(VirtualTourStateTypes.PLAY);
        }
    }

    /**
     * 
     * @param meshId Id della mesh corrispondente ad un'opera (passare null in caso si debba usare il jsonId)
     * @param jsonId Id dell'oggetto json corrispondente ad un'opera
     * Imposta l'opera selezionata propagando il valore ai subscribers.
     * In caso vengano passati due parametri valorizzati verra considerato il meshId.
     * 
     */
    setSelectedOperaById = (meshId: string | null, jsonId?: number) => {
        var jId: number;
        if (meshId) {
            jId = OperasIdIndexMap[meshId];
        } else if (jsonId) {
            jId = jsonId;
        } else {
            console.error("setSelectedOperaById: Nessun ID parametro e' stato fornito");
            return;
        }

        if (jId !== null || jId !== undefined) {

            const p = this._positions[jId].position;
            const t = this._positions[jId].target;
            const camPos = new Vector3(p.x, p.y, p.z)
            const camTar = new Vector3(t.x, t.y, t.z)



            console.log("ImagesMap: ", this.imagesMap);

            var res: OperaData = { ...this._descriptions[jId], ...{ cameraPosition: camPos, targetPoint: camTar }, jsonId: jId, imageUrl: this.imagesMap[jId] };

            console.debug("Selected opera: ", res);

            // Propago il valore
            this.selectedOpera.next(res);

        } else {
            console.error("setSelectedOperaById: L'id fornito non e' presente nella raccolta delle opere.")
        }
    }

    /**
     * @param enableLoop Se false, impedisce che il ciclo di opere riparta da capo. Default true.
     * Imposta l'opera successiva e ne propaga il valore ai subscribers usando setSelectedOperaById.
     */
    setNextOpera = (enableLoop: boolean = true) => {
        const operasLength = Object.keys(OperasIdIndexMap).length;
        if ((this.selectedOpera.value?.jsonId === operasLength && enableLoop) || this.selectedOpera.value?.jsonId === null) {
            // [DEBUG]
            if (this.selectedOpera.value?.jsonId === null) {
                console.error("Unexpected use of setNextOpera before the first opera has been setted. Fallback alla prima opera.")
            }
            this.setSelectedOperaById(null, 1);
        } else {
            const newId = ((this.selectedOpera.value?.jsonId || 0) + 1);
            this.setSelectedOperaById(null, newId);
        }
    }

    /**
    * @param enableLoop Se false, impedisce che il ciclo di opere riparta da capo. Default true.
    * Imposta l'opera precedente e ne propaga il valore ai subscribers usando setSelectedOperaById.
    */
    setPrevOpera = (enableLoop: boolean = true) => {
        // Ricavo la length delle opere
        const operasLength = Object.keys(OperasIdIndexMap).length;

        if ((this.selectedOpera.value?.jsonId === 1 && enableLoop) || this.selectedOpera.value?.jsonId === null) {
            // [DEBUG]
            if (this.selectedOpera.value?.jsonId === null) {
                console.error("Unexpected use of setPrevOpera before the first opera has been setted. Fallback all'ultima opera.")
            }
            this.setSelectedOperaById(null, (operasLength - 1));
        } else {
            const newId = ((this.selectedOpera.value?.jsonId || 0) - 1);
            this.setSelectedOperaById(null, newId);
        }
    }

    /**
     * Alterna lo stato del tour virtuale tra PLAY e PAUSE e ne propaga il valore ai subscribers.
     */
    toggleTourState = (forcedValue?: VirtualTourStateType) => {
        if (forcedValue) {
            this.virtualTourState.next(forcedValue);
        } else {
            switch (this.virtualTourState.value) {
                case VirtualTourStateTypes.PAUSE:
                    this.virtualTourState.next(VirtualTourStateTypes.PLAY)
                    break;
                case VirtualTourStateTypes.PLAY:
                    this.virtualTourState.next(VirtualTourStateTypes.PAUSE)
                    break;
                default:
                    console.error("toggleTourState: Lo stato del tour non rientra tra quelli definiti in VirtualTourStateType. Fallback a PAUSE");
            }
        }
    }





}


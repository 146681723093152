import React from 'react';
import { Icons } from '../../../share/utils';
import style from './Help.module.css';


interface HelpState {
    isVisible: boolean,
    isInstanced: boolean,
    isDesktop: boolean,
}


const tutorialMobile = [
    // {
    //     icon: "icon-mobile",
    //     label: `Avvia la modalità Manuale (<i class="icon-arrowkeys"></i>) per navigare liberamente 
    //     o la modalità Tour Virtuale (<i class="icon-play-1"></i>) per un'esperienza automatizzata`
    // },
    {
        icon: "icon-play-1",
        label: `Avvia la modalità Tour Virtuale per un'esperienza automatizzata`
    },
    {
        icon: "icon-arrowkeys",
        label: `Avvia la modalità Manuale per navigare liberamente`
    },
    {
        icon: "icon-tap",
        label: "Clicca Su Un'Opera Per Raggiungerla"
    },
    {
        icon: "icon-swipe-vh",
        label: "Trascina Un Dito Verticalmente Per Muoverti Avanti e Indietro"
    },
    {
        icon: "icon-swipe-hz",
        label: "Trascina Un Dito Orizzontalmente Per Muoverti a Destra e Sinistra"
    },
    {
        icon: "icon-swipe-db",
        label: "Trascina Due Dita Per Ruotare La Telecamera"
    },
    {
        icon: "icon-show",
        label: "Visualizza i dettagli dell'opera e visualizza l'opera nel dettaglio"
    },
]

const tutorialDesktop = [
    // {
    //     icon: "icon-desktop",
    //     label: `Avvia la modalità Manuale (<i class="icon-arrowkeys"></i>) per navigare liberamente 
    //     o la modalità Tour Virtuale (<i class="icon-play-1"></i>) per un'esperienza automatizzata`
    // },
    {
        icon: "icon-play-1",
        label: `Avvia la modalità Tour Virtuale per un'esperienza automatizzata`
    },
    {
        icon: "icon-arrowkeys",
        label: `Avvia la modalità Manuale per navigare liberamente`
    },
    {
        icon: "icon-click",
        label: "Clicca su un'opera per raggiungerla"
    },
    {
        icon: "icon-wasdkeys",
        label: "Usa le frecce o “WASD” per spostarti"
    },
    {
        icon: "icon-cameracontrol",
        label: "Clicca e muovi il mouse per ruotare la visuale"
    },
    {
        icon: "icon-show",
        label: "Visualizza i dettagli dell'opera e visualizza l'opera nel dettaglio"
    },
]

export default class Help extends React.Component<{ active: boolean, close: () => void }, HelpState> {

    constructor(props: any) {
        super(props);
        this.state = {
            isVisible: false,
            isInstanced: false,
            isDesktop: false
        }
    }


    componentDidMount() {
        this.setState({ isDesktop: window.innerWidth >= 911 });
    }

    componentDidUpdate(oldProps: any, oldState: HelpState) {

        // console.debug("oldProps, oldState, snap: ", oldProps, oldState)
        // console.debug("props, state, snap: ", this.props, this.state);
        if (this.props.active && (oldProps.active !== this.props.active)) {
            this.show();
        } else if (!this.props.active && (oldProps.active !== this.props.active)) {
            this.hide();
        }
    }


    hide = () => {
        this.setState({ isVisible: false });
        setTimeout(() => {
            this.setState({ isInstanced: false });
        }, 650)
    }


    show = () => {
        this.setState({ isVisible: false, isInstanced: true });
        setTimeout(() => {
            this.setState({ isVisible: true });
        }, 650)
    }

    render() {
        var opacityStyle: { [key: string]: any } = {
            opacity: this.state.isVisible ? 1 : 0,
            display: this.state.isInstanced ? 'flex' : 'hidden',
            transition: 'opacity 0.6s, display 0.8s',
        }
        const tips = this.state.isDesktop ? tutorialDesktop : tutorialMobile;

        return (
            <React.Fragment>
                {
                    // RIMUOVERE "!" PER RIPRISTINARE FUNZIONAMENTO
                    this.state.isInstanced
                    &&
                    <div className={`${style.container}`} style={opacityStyle} >
                        <div className={`${style.header} flex f-row j-start a-center`}>
                            <i className={`${style.closeIcon} ${Icons.ARROW_LEFT}`} onClick={this.props.close}></i>
                            <h1 className={`${style.title}`} >Come funziona?</h1>
                        </div>
                        <div className={`${style.tipsContainer} flex f-row f-wrap`}>
                            {tips.map((x, i) =>
                                <TutorialRow key={`tutorial-tips-${i}`} label={x.label} icon={x.icon} />
                            )}
                        </div>
                        <div className={`${style.footer} flex f-row j-end a-center`}>
                            <i className={`${Icons.DESKTOP} ${style.footerIcon}`} onClick={() => {
                                this.setState({ isDesktop: true });
                            }}></i>
                            <span style={{ opacity: 0.5 }}>
                                |
                            </span>
                            <i className={`${Icons.MOBILE_TABLET} ${style.footerIcon}`} onClick={() => {
                                this.setState({ isDesktop: false });
                            }}></i>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}


export function TutorialRow(props: { label: string, icon: string }) {
    return (
        < div className={`${style.splashTutotrialRow} flex f-row a-center j-between`}>
            <div className={`${style.iconContainer} flex-center-all`}>
                <i className={`${props.icon} splash-tutorial-icon`}></i>
            </div>
            <div className={`${style.text}`} dangerouslySetInnerHTML={{ __html: props.label }} ></div>
        </div >
    )
}
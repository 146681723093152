import App from "../App";
import { Camera, Color4, GamepadManager, ImageProcessingPostProcess, MeshBuilder, Scene, UniversalCamera, Vector3 } from "@babylonjs/core";
// import { Direction } from "../Overlay/Utils/DirectionButton";


const cameraOptions = {
    // EXTRA COLLISION BOX
    addCollisionBox: false,
    // R for RESET
    resetListener: true,
    // POST PROCESS
    applyPostProcess: false,
    // GAME PAD MANAGEMENT
    gamepagSupport: true
}

// CAMERA START POINT
export const cameraStartPoint = { x: -5.82, y: 1.7, z: -6.75 };
// CAMERA COLLIDER DIMENTION
const cameraColliderDimention = new Vector3(0.4, 0.3, 0.4);

// INIZIALIZZA LA CAMERA
export function initCamera(this: App) {
    // BIND FUNCTIONS
    const keyDownListener = cameraKeydownListener.bind(this);
    const gamepadSupport = gamepadManager.bind(this);


    this.camera = new UniversalCamera('camera', new Vector3(cameraStartPoint.x, cameraStartPoint.y, cameraStartPoint.z), this.scene);

    // CAMERA PROPERTIES
    this.camera.minZ = 0.01;
    this.camera.maxZ = 1000;
    this.camera.fov = 0.7;
    this.camera.inertia = 0.93;

    // SPECIFIC UniversalCamera PROPERTIES
    (this.camera as UniversalCamera).setTarget(new Vector3(cameraStartPoint.x, cameraStartPoint.y, cameraStartPoint.z + 2));
    (this.camera as UniversalCamera).speed = 0.05;
    (this.camera as UniversalCamera).checkCollisions = true;
    (this.camera as UniversalCamera).angularSensibility = 5000;
    (this.camera as UniversalCamera).noRotationConstraint = false;
    (this.camera as UniversalCamera).applyGravity = true;
    (this.camera as UniversalCamera).ellipsoid = new Vector3(cameraColliderDimention.x, cameraColliderDimention.y, cameraColliderDimention.z);
    (this.camera as UniversalCamera).onCollide = (e: any) => { }


    // POST PROCESSING
    if (cameraOptions.applyPostProcess) {
        var postProcess = new ImageProcessingPostProcess("processing", 1, this.camera, 1);
        postProcess.contrast = 4.5;
        postProcess.exposure = 0.53;
    }


    // CREATE CAMERA BOX (FOR CUSTOM COLLISION DETECTION)
    if (cameraOptions.addCollisionBox) {
        createCameraBox(this.camera, this.scene);
    }

    // IMPOSTA TASTI MOVIMENTO
    setKeys.call(this);

    // Attach the camera tsso the canvas
    this.camera.attachControl(this.canvas, false);

    if (cameraOptions.gamepagSupport) {
        gamepadSupport();
    }


    // KEYDOWN CAMERA LISTENER
    window.addEventListener('keydown', keyDownListener, { passive: true });
}


export function cameraKeydownListener(this: App, e: any) {
    if (cameraOptions.resetListener && e.key === 'r') {
        console.log("R pressed. Reset camera");
        this.resetCamera();
    }
}

// RESET CAMERA POSITION
export function resetCamera(this: App) {
    this.camera.position = new Vector3(cameraStartPoint.x, cameraStartPoint.y, cameraStartPoint.z);
    // this.camera.setTarget(new Vector3(sp.x, sp.y, sp.z + 2));
}



// CREA UN CUBO FIGLIO DELLA CAMERA
function createCameraBox(camera: Camera, scene: Scene) {
    const cameraBox = MeshBuilder.CreateBox("body", { size: 0.1, faceColors: [new Color4(0, 0, 0, 1)] }, scene);
    cameraBox.parent = camera;
    cameraBox.isPickable = false;
    cameraBox.ellipsoid = new Vector3(cameraColliderDimention.x, cameraColliderDimention.y, cameraColliderDimention.z);
    console.log(cameraBox)
}



// GAMEPAD SUPPORT
function gamepadManager(this: App) {
    var gamepadManager = new GamepadManager();
    gamepadManager.onGamepadConnectedObservable.add((gamepad, state) => {
    });
    gamepadManager.onGamepadDisconnectedObservable.add((gamepad, state) => {
    });

    gamepadManager.onGamepadConnectedObservable.add((gamepad: any, state) => {
        gamepad.onButtonDownObservable.add((button: any, state: any) => {
            this.resetCamera();
        })
    })
}


// IMPOSTA I TASTI PER MUOVERE LA CAMERA
function setKeys(this: any) {
    this.camera.keysLeft = [65, 37];
    this.camera.keysUp = [87, 38];
    this.camera.keysRight = [68, 39];
    this.camera.keysDown = [83, 40];
}


// LIMITA IL MOVIMENTO DELLA CAMERA VERTICALMENTE
export function limitCameraY(this: App) {
    const deltaV = 0.4; // Vertical headroom
    const deltaH = 0.6; // Horizontal headroom

    if (this.camera.position.y > cameraStartPoint.y + deltaV) {
        this.camera.position.y = cameraStartPoint.y + deltaV;
    } else if (this.camera.position.y < cameraStartPoint.y - deltaH) {
        this.camera.position.y = cameraStartPoint.y - deltaH;
    }
}







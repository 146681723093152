import React from 'react';
import './DescriptionPanel.css';
import { FocusPage } from '../FocusPage/FocusPage';
import { TourBloc } from '../../../bloc/TourBloc';
import { OperaType } from '../../../bloc/TourBlocUtils';
import { Icons } from '../../../share/utils';

export const quadro: any = {
    collinaalba: 1,
    autunno: 2,
    vignaalba: 3,
    granobiondo: 4,
    montemagno: 5,
    montemagnopaesaggio: 6,
    paesaggiosintesi: 7,
    vignacomune: 8,
    casottorosso: 9,
    vigna: 10,
    granosera: 11,
    granogiorno: 12,
    granomattino: 13,
    prospettivamontemagno: 14,
    torrecontini: 15,
    paesaggiomonferrino: 16,
    contadino: 17,
    monferratoborghi: 18,
    immensita: 19,
    entratacastello: 20,
    ristorantecrova: 21,
    giardinotassi: 22,
    piazzamartiri: 23,
    art900: 24,
    nerone: 25,
    ulisse: 26,
    guttuso: 27,
    zigaina: 28,
    lettera: 29
}


interface DPanelState {
    active: boolean,
    actualSelection: OperaType,
    id?: number | null,
    focusActive: boolean,
    tourActive: boolean
}

export class DescriptionPanel extends React.Component<{ showDescription: boolean }, DPanelState | any> {

    tourBloc: TourBloc;

    constructor(props: any) {
        super(props);
        this.tourBloc = new TourBloc();
        this.state = {
            id: null,
            actualSelection: null,
            active: false,
            clickDown: null,
            focusActive: false,
            tourActive: false
        }
    }

    componentDidMount() {
        this.tourBloc.selectedOpera.subscribe((snap: OperaType) => {
            this.setState({ actualSelection: snap })
        })
    }

    componentDidUpdate() {
        this.setState((s: any) => {
            if (s.active !== this.props.showDescription) {
                if (!this.props.showDescription) {
                    console.log("Hiding details")
                    const canvas = document.getElementById("gav");
                    canvas?.focus();
                } else {
                    console.log("Showing details")
                }
                return { active: this.props.showDescription }
            }
        })
    }

    hide = () => {
        this.setState({ active: false });
        const canvas = document.getElementById("gav");
        canvas?.focus();
    }


    onViewImage = () => {
        this.setState({ focusActive: true });
    }

    onHideImage = () => {
        this.setState({ focusActive: false });
    }

    render() {

        const mainClass = ("d-panel-main " + (this.state.active ? '' : 'd-panel-main-inactive'))
        return (
            <React.Fragment>
                {/* IMMAGINE IN SOVRAIMPRESSIONE FOCUS */}
                {
                    this.state.focusActive && this.state.actualSelection?.imageUrl
                    &&
                    <FocusPage imageUrl={this.state.actualSelection?.imageUrl} onCloseMe={this.onHideImage} />
                }

                <div className={mainClass} onClick={() => this.tourBloc.showDescription.next(false)}>

                    <div>
                        <div className="d-panel-buttons-panel" >
                            <div className="d-panel-icon-container d-panel-top-level-button" >
                                <div className="icon-label  d-panel-top-level-button">Chiudi</div>
                                <div className="d-panel-close-icon  d-panel-top-level-button" onClick={() => this.tourBloc.showDescription.next(false)} >
                                    <i className={`${Icons.DOWN}  d-panel-top-level-button"`} ></i>
                                </div>
                            </div>
                            {/* <div className="d-panel-icon-container">
                                <div className="icon-label">Vai all'opera</div>
                                <div className="d-panel-goto-icon" onClick={() => null} >
                                    <i className="icon-steering-wheel" ></i>
                                </div>
                            </div> */}
                            {
                                this.state.actualSelection?.imageUrl
                                &&
                                <div className="d-panel-icon-container">
                                    <div className="icon-label">Focus</div>
                                    <div className="d-panel-goto-icon" onClick={(e) => { e.stopPropagation(); this.onViewImage(); }} >
                                        <i className={`${Icons.FULLSCREEN}`} ></i>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="d-panel-title" onClick={(e) => { e.stopPropagation(); }} >{this.state.actualSelection?.title}</div>

                        <div className="d-panel-subdata-container flex f-row" >
                            <div className="d-panel-place" onClick={(e) => { e.stopPropagation(); }} >{this.state.actualSelection?.place}</div>
                            <div className="d-panel-place" onClick={(e) => { e.stopPropagation(); }} >{this.state.actualSelection?.year}</div>
                            <div className="d-panel-place" onClick={(e) => { e.stopPropagation(); }} >{this.state.actualSelection?.type}</div>
                        </div>
                        <div className="d-panel-description" dangerouslySetInnerHTML={{ __html: (this.state.actualSelection?.description || "") }} onClick={(e) => { e.stopPropagation(); }} ></div>
                        <div className="d-panel-description-by" onClick={(e) => { e.stopPropagation(); }} >{this.state.actualSelection?.descriptionBy}</div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}



import React, { useState } from 'react';
import style from './ButtonsFloatingContainer.module.css'

interface ButtonsFloatingContainerProps {
    [key: string]: any
}
export function ButtonsFloatingContainer(props: ButtonsFloatingContainerProps) {
    const [show, setShow] = useState(true);
    return (
        <div className={`${style.mainContainer} flex f-column-reverse j-start a-center`}>
            <div className={`${style.icon} ${show ? '' : style.iconNoRotation} flex-center-all`} onClick={(e) => { e.stopPropagation(); setShow((s) => !s) }} >
                <i className={`icon-play`} ></i>
            </div>
            <div className={`flex f-column-reverse j-center a-center ${show ? style.show : style.hide}`} >
                {
                    props.children
                }
            </div>
        </div>
    )
}


import React from 'react';
import './SplashScreen.css';
import loader from '../assets/splashscreen/loagin.png';
import bannerDown from '../assets/splashscreen/banner-down.png';
import bannerUp from '../assets/splashscreen/banner-up.png';


interface SplashScreenState {
    isVisible: boolean,
    isInstanced: boolean
}


const tutorialMobile = [
    {
        icon: "icon-play-1",
        label: `Avvia la modalità Tour Virtuale per un'esperienza automatizzata`
    },
    {
        icon: "icon-arrowkeys",
        label: `Avvia la modalità Manuale per navigare liberamente`
    },
    {
        icon: "icon-tap",
        label: "Clicca Su Un'Opera Per Raggiungerla"
    },
    {
        icon: "icon-swipe-vh",
        label: "Trascina Un Dito Verticalmente Per Muoverti Avanti e Indietro"
    },
    {
        icon: "icon-swipe-hz",
        label: "Trascina Un Dito Orizzontalmente Per Muoverti a Destra e Sinistra"
    },
    {
        icon: "icon-swipe-db",
        label: "Trascina Due Dita Per Ruotare La Telecamera"
    },
    {
        icon: "icon-show",
        label: "Visualizza i dettagli dell'opera e visualizza l'opera nel dettaglio"
    },
]

const tutorialDesktop = [
    {
        icon: "icon-play-1",
        label: `Avvia la modalità Tour Virtuale per un'esperienza automatizzata`
    },
    {
        icon: "icon-arrowkeys",
        label: `Avvia la modalità Manuale per navigare liberamente`
    },
    {
        icon: "icon-click",
        label: "Clicca su un'opera per raggiungerla"
    },
    {
        icon: "icon-wasdkeys",
        label: "Usa le frecce o “WASD” per spostarti"
    },
    {
        icon: "icon-cameracontrol",
        label: "Clicca e muovi il mouse per ruotare la visuale"
    },
    {
        icon: "icon-show",
        label: "Visualizza i dettagli dell'opera e visualizza l'opera nel dettaglio"
    },
]

export default class SplashScreen extends React.Component<{ active: boolean, loadingPerc: number }, SplashScreenState> {

    constructor(props: any) {
        super(props);
        this.state = {
            isVisible: true,
            isInstanced: true
        }
    }

    componentDidUpdate(oldProps: any, oldState: SplashScreenState) {
        // console.debug("oldProps, oldState, snap: ", oldProps, oldState)
        // console.debug("props, state, snap: ", this.props, this.state);
        if (this.props.active && (oldProps.active !== this.props.active)) {
            this.show();
        } else if (!this.props.active && (oldProps.active !== this.props.active)) {
            this.hide();
        }
    }


    hide = () => {
        this.setState({ isVisible: false });
        setTimeout(() => {
            this.setState({ isInstanced: false });
        }, 650)
    }


    show = () => {
        this.setState({ isVisible: false, isInstanced: false });
        setTimeout(() => {
            this.setState({ isVisible: true });
        }, 650)
    }

    render() {
        var opacityStyle: { [key: string]: any } = {
            opacity: this.state.isVisible ? 1 : 0,
            display: this.state.isInstanced ? 'flex' : 'hidden',
            transition: 'opacity 0.6s, display 0.8s',
        }
        const tips = (window.innerWidth >= 911) ? tutorialDesktop : tutorialMobile;

        return (
            <React.Fragment>
                {
                    // RIMUOVERE "!" PER RIPRISTINARE FUNZIONAMENTO
                    this.state.isInstanced
                    &&
                    <div className="splash-container" style={opacityStyle} >
                        <div className="forarts-presenta flex a-start" style={{ backgroundImage: `url(${bannerUp})` }} >
                            {/* <img src={hzNaming} /> */}
                            <i className="icon-hz_naming splash-screen-forarts-text"></i>
                            {/* <span className="splash-screen-forarts-text" >ForArts</span> */}
                        </div>
                        <h1 className="splash-gav-title" >Galleria Virtuale “Davide Lajolo”</h1>
                        <div className="splash-screen-footer" >
                            <span className="splash-loading-perc flex-center-all">{this.props.loadingPerc}</span>
                            <div className="loaders-container" >
                                <img src={loader} className="logo" alt=" " />
                                {/* <img src="assets/utils/loagin.png" className="loader" alt=" " /> */}
                            </div>
                        </div>
                        {tips.map((x, i) =>
                            <TutorialRow key={`tutorial-tips-${i}`} label={x.label} icon={x.icon} />
                        )}
                        <div className="splash-loading-advice">Nota: Il caricamento potrebbe richiedere alcuni minuti</div>

                        <div className="forarts-footer flex f-column j-end a-end" style={{ backgroundImage: `url(${bannerDown})` }} >
                            <div className="footer-diritti" >&#169;{new Date().getFullYear()} - Associazione Culturale ForArts</div>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}


export function TutorialRow(props: { label: string, icon: string }) {
    return (
        < div className="splash-tutotrial-row flex f-row a-center j-between">
            <div className="splash-icon-container flex-center-all">
                <i className={`${props.icon} splash-tutorial-icon`}></i>
            </div>
            <div className="splash-tutorial-text" >{props.label}</div>
        </div >
    )
}
import { Animation, Scene, UniversalCamera } from "@babylonjs/core";
import App from "../App";
import { OperasIdIndexMap, TourTypes, VirtualTourStateTypes } from "../bloc/TourBlocUtils";

/**
 * Variabile contenente l'id selezionato al clickDown.
 * Utile per la verifica di eguaglianza al clickUp.
 */
var clickDown: string;

/**
 * 
 * @param this CoreApp
 * @param evt Event
 * @param pickResult Mesh "touched"
 * Funzione listener  per il pointerDown della scena.
 */
export function onPointerDown(this: App, evt: any, pickResult: any) {
    if (pickResult.hit) {
        const id = pickResult.pickedMesh.id;
        const point = pickResult.pickedPoint;
        console.debug("Pick: ", id)
        console.debug("Point: ", `${point.x},${point.y},${point.z}`)
        if (
            Object.keys(OperasIdIndexMap).includes(id)
        ) {
            clickDown = id;
        }
    }
}

/**
 * 
 * @param this CoreApp
 * @param evt Event
 * @param pickResult Mesh "touched"
 * Funzione listener  per il pointerUp della scena.
 */
export function onPointerUp(this: App, evt: any, pickResult: any) {
    if (pickResult.hit) {

        const id = pickResult.pickedMesh.id;

        // Se l'oggetto cliccato e' un quadro
        if (
            Object.keys(OperasIdIndexMap).includes(id) && id === clickDown
        ) {
            var imageUrl = null;
            try {
                imageUrl = pickResult.pickedMesh.material.albedoTexture._buffer;

                console.log("Image url: ", imageUrl);
            } catch (err) {
            }
            // const desc = (descriptions as any)[quadro[id]];
            this.tourBloc.setTourType(TourTypes.MANUAL);
            this.tourBloc.toggleTourState(VirtualTourStateTypes.PAUSE);
            this.tourBloc.setSelectedOperaById(id);
            // MOSTRO LE INFORMAZIONI IN SOVRAIMPRESSIONE
            // console.log(id, desc);
            // return { actualSelection: desc, active: true, id: quadro[id], clickDown: null, imageUrl: imageUrl };
            clickDown = '';
        }
        return {}
    }
}


// ANIMA IL MOVIMENTO DELLA CAMERA A UNA POSIZIONE E UN TARGET DESIGNATI
export function animateCamera(scene: Scene, pos: any, target: any) {
    if (scene && scene.activeCamera) {
        console.log('Animate eCamera function', pos, target);
        var position = new Animation("camPos", "position", 60, Animation.ANIMATIONTYPE_VECTOR3, Animation.ANIMATIONLOOPMODE_CYCLE);
        var rotation = new Animation("camTarg", "lockedTarget", 60, Animation.ANIMATIONTYPE_VECTOR3, Animation.ANIMATIONLOOPMODE_RELATIVE);
        var keys = [{ frame: 0, value: scene.activeCamera.position }, { frame: 100, value: pos }];
        var keys2 = [{ frame: 0, value: (scene.activeCamera as UniversalCamera).getTarget() }, { frame: 100, value: target }];
        position.setKeys(keys);
        rotation.setKeys(keys2);
        scene.activeCamera.animations.push(position);
        scene.activeCamera.animations.push(rotation);
        console.log(scene);
        console.log(scene.activeCamera);
        scene.beginAnimation(scene.activeCamera, 0, 100, false, 1, () => {
            console.log("Animation ends");
            if (scene.activeCamera) {
                (scene.activeCamera as UniversalCamera).lockedTarget = undefined;

                // NAVIGO ESEGUO NAVIGAZIONE SCHIANTATA PER BROWSER INCAPACI DI ANIMARE (Firefox mobile)
                scene.activeCamera.position = pos;
                (scene.activeCamera as UniversalCamera).setTarget(target)
            }
        });
    }
}

/**
 * Tempo in millisecondi che deve intercorrere tra ogni passaggio 
 * all'opera successiva nella modalita' tour.
 */
const tourTimePerOpera: number = 10000;

/**
 * @param this App. 
 * Attiva un interval della durata di tourTimePeropera const che porta all'opera successiva.
 */
export function startTour(this: App) {
    this.tourBloc.setNextOpera();
    this.tourInterval = setInterval(this.tourBloc.setNextOpera, tourTimePerOpera)
}

/**
 * @param this App. 
 * Interrompe l'interva;l del tour.
 */
export function stopTour(this: App) {
    clearInterval(this.tourInterval);
}

/**
 * 
 * @param event Evento keydown.
 * Gestisce l'evento keydown per fornire funzioni shortcut come next, previous o reset.
 */
export function keyDownHandler(this: App, event: any) {
    switch (event.key) {
        case 'p':
        case 'P':
            this.tourBloc.setPrevOpera();
            break;

        case 'n':
        case 'N':
            this.tourBloc.setNextOpera();
            break;
        case 'r':
        case 'R':
            this.tourBloc.setTourType(TourTypes.UNSET);
            this.tourBloc.toggleTourState(VirtualTourStateTypes.PAUSE);
            this.tourBloc.setSelectedOperaById(null, 1)
            break;
    }
}
import { Vector3 } from "@babylonjs/core";


/**
 * Mappa MESH_OPERA_ID  --> JSON_DATA_PROPERTY
 * 
 * Continene gli indici da usare per leggere il json contenente i dati delle opere
 * 
 */
export const OperasIdIndexMap: { [key: string]: number } = {
    collinaalba: 1,
    autunno: 2,
    vignaalba: 3,
    granobiondo: 4,
    montemagno: 5,
    montemagnopaesaggio: 6,
    paesaggiosintesi: 7,
    vignacomune: 8,
    casottorosso: 9,
    vigna: 10,
    granosera: 11,
    granogiorno: 12,
    granomattino: 13,
    prospettivamontemagno: 14,
    torrecontini: 15,
    paesaggiomonferrino: 16,
    contadino: 17,
    monferratoborghi: 18,
    immensita: 19,
    entratacastello: 20,
    piazzamartiri: 21,
    ristorantecrova: 22,
    giardinotassi: 23,
    art900: 24,
    nerone: 25,
    ulisse: 26,
    guttuso: 27,
    zigaina: 28,
    lettera: 29
}


/**
 * L'interfaccia che rappresenta i dati di un'opera
 */
export interface OperaData {
    jsonId: number;
    title: string;
    place: string;
    type: string;
    year: string;
    imageUrl: string;
    description: string;
    descriptionBy: string;
    cameraPosition: Vector3;
    targetPoint: Vector3;
}
/**
 * Opera type.
 * Include l'interfaccia OperaData o null.
 */
export type OperaType = OperaData | null;



/**
 * Stati possibili per il virtual tour. 
 * Si puo' indicare attraverso la costante VirtualTourStateTypes.
 */
export type VirtualTourStateType = "PLAY" | "PAUSE";

/**
 * Costante shortcut per indicare un VirtualTourStateType.
 */
export const VirtualTourStateTypes: { PLAY: VirtualTourStateType, PAUSE: VirtualTourStateType } = {
    PLAY: "PLAY",
    PAUSE: "PAUSE"
}




/**
 * Tipi possibili di modalita' tour.
 * Nota: E' possibile utilizzare la costante TourTypes per indicare uno di questi tipi.
 */
export type TourType = "MANUAL" | "VIRTUAL" | "UNSET";

/**
 * Costante Shortcut per indicare un TourType.
 */
export const TourTypes: {
    MANUAL: TourType,
    VIRTUAL: TourType,
    UNSET: TourType
} = {
    MANUAL: "MANUAL",
    VIRTUAL: "VIRTUAL",
    UNSET: "UNSET"
}

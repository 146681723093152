import React from 'react';
import style from './GenericButton.module.css';

export type ButtonStyleType = "DEFAULT" | "ROUNDED";


/**
 * 
 * @param props 
 * Bottone generico con label e icona.
 * Possono essere impostati diversi stili.
 * Puo essere diabilitato.
 */
export function GenericButton(props: {
    onClick: () => void,
    icon: string,
    label: string,
    styleType?: ButtonStyleType,
    disabled?: boolean,
    bigIcon?: boolean
}) {
    // SET STYLE CLASS
    var styleT: string;
    switch (props.styleType) {
        default:
            styleT = style.default;
    }

    return (
        <div className={`${style.container} ${styleT} ${props.disabled ? style.disabled : ''} flex-center-all unselect-undrag`} onClick={props.onClick} >
            {/* LABEL */}
            {
                props.label
                &&
                <span className={`${style.label} unselect-undrag`} >{props.label}</span>
            }
            <div className={`${style.subContainer} flex-center-all`}>
                {/* ICONA */}
                <i className={`${props.icon} ${style.icon} ${props.bigIcon ? style.bigIcon : ''}`} ></i>
            </div>
        </div>
    )
}
import { AbstractMesh, SceneLoader } from "@babylonjs/core";
import App from "../App";
import { OperasIdIndexMap } from "../bloc/TourBlocUtils";
import { isProduzione } from "./share";


const options = {
    operaLight: false,
    foreverSplash: false
}


export async function initModel(this: App, setLoadingPerc: any) {
    const browser = getBrowser();
    var url =
        process.env.NODE_ENV === "production"
            ?
            "https://firebasestorage.googleapis.com/v0/b/nuvola-di-punti.appspot.com/o/binaries%2Fgdl-3.0.4-official.babylon?alt=media&token=b3f95212-5be7-457a-bbe2-1aea4ce416e9"
            :
            "assets/models/babylon/gdl-3.0.4-official.babylon"

    // url = "assets/models/babylon/scene.babylon";

    SceneLoader.ImportMesh(null, url, "", this.scene,
        // ON DONE
        (meshes) => {
            meshes.forEach(x => {
                (meshOptions.bind(this))(x, browser);
            })

            if (!options.foreverSplash || isProduzione()) {
                this.engine.hideLoadingUI();
            }
        },
        // ON PROGRESS
        (e: any) => {
            var perc;
            perc = Math.floor((e.loaded / e.total) * 100);
            if (perc === Infinity) {
                setLoadingPerc(0);
            } else {
                setLoadingPerc(perc);
            }
        })
}


function meshOptions(this: App, x: AbstractMesh, browser: string) {
    const id = x.id;

    // console.log("Id: ", id);

    // }
    // SHADOWS
    // shadowGenerator.addShadowCaster(x, true);
    // x.receiveShadows = true;

    // ALL VISIBLE
    // x.isVisible = true;
    // x.getChildMeshes().forEach(y => { y.isVisible = true; })

    // UNPICKABLE
    if (id === 'ph-glass' || id === 'artworks-supports') {
    }
    switch (x.id) {
        case "ph-glass":
        case "artworks-supports":
            x.isPickable = false;
            break;
        default:
            x.checkCollisions = true;
    }

    // FIX PARETI PER FIREFOX MOBILE
    if (browser.toLowerCase().includes("firefox") && window.innerWidth <= 729) {
        switch (id) {
            case "walls":
                x.isVisible = false;
                break;
        }
    } else {
        switch (id) {
            case "walls-firefox":
                x.isVisible = false;
                break;
        }
    }

    if (OperasIdIndexMap[id]) {
        var imageUrl = null;
        try {
            imageUrl = (x as any).material.albedoTexture._buffer;
            if (imageUrl) {
                this.tourBloc.imagesMap = { ...this.tourBloc.imagesMap, [OperasIdIndexMap[id].toString()]: imageUrl }
            }
            console.log("Image url: ", imageUrl);
        } catch (err) {
        }
    }


    // if (options.operaLight && !!(operasPosition as unknown as any)[quadro[id]]) {
    //     const p = (operasPosition as unknown as any)[quadro[id]].position;
    //     const t = (operasPosition as unknown as any)[quadro[id]].target;
    //     const vp = new Vector3(p.x, p.y + 0.7, p.z);
    //     const vt = new Vector3(t.x, t.y + 0.15, t.z).subtract(vp);
    //     // const light = new PointLight(`${id}-light`, (operasPosition as unknown as any)[quadro[id]].position, this.scene);
    //     const light = new SpotLight(`${id}-light`, vp, vt, Math.PI / 3, 100, this.scene);
    //     light.intensity = 5
    //     console.log("Add spotlight: ", light)
    // }



    this.meshes.push(x);

}








// BROWSER
function getBrowser(): string {
    const browserType = (function () {
        var ua = navigator.userAgent, tem,
            M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
        return M.join(' ');
    })();
    return browserType;
}
import React from 'react';
import './Overlay.css';


export function Overlay(props: any) {
    return (
        <React.Fragment>
            <div className="overlay-main-container">
                {props.children}
            </div>
        </React.Fragment>
    )
}
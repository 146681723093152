
/**
 * Costante shorcut per indicare la classe di un icona Faicons.
 */
export const Icons = {
    PLAY: "icon-play-1",
    STOP: "icon-stop",
    PAUSE: "icon-pause-1",
    NEXT: "icon-next",
    PREV: "icon-previous",
    RESET: "icon-undo",
    GOTO: "icon-steering-wheel",
    START: 'icon-start',
    HOW: 'icon-how',
    DOWN: "icon-down",
    UP: "icon-up",
    RIGHT: "icon-right",
    LEFT: "icon-left",
    ARROW_DOWN: "icon-arrow_down",
    ARROW_UP: "icon-arrow_up",
    ARROW_RIGHT: "icon-arrow_right",
    ARROW_LEFT: "icon-arrow_left",
    FULLSCREEN: "icon-resize-full",
    FULLSCREEN_LEAVE: "icon-resize-small",
    FORARTS: 'icon-hz_naming',
    SWIPE_HZ: 'icon-swipe-hz',
    SWIPE_VH: 'icon-swipe-vh',
    SWIPE_DB: 'icon-swipe-db',
    CAMERA_CONTROL: 'icon-cameracontrol',
    TAP: 'icon-tap',
    CLICK: 'icon-click',
    KEYS_WASD: 'icon-wasdkeys',
    KEYS_ARROW: 'icon-arrowkeys',
    LOAD_TIME: 'icon-load-time',
    LINKEDIN: 'icon-linkedin',
    FACEBOOK: 'icon-facebook',
    INSTAGRAM: 'icon-instagram',
    SHOW: 'icon-show',
    DESKTOP: "icon-desktop",
    MOBILE_TABLET: "icon-mobile"
}